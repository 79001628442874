export const environment = {
  production: true,
  domain: 'https://pipooh.com.br/',
  // domain: 'https://sistema.zull.com.br/',
  api: 'https://api.pipooh.com.br/'
};

export const environmentAspNet = {
  production: true,
  domain: 'https://pipooh.com.br/',
  api: 'https://apiv2.pipooh.com.br/'
  // api: 'http://localhost:24066/'
};
